.page-content-wrapper {
  min-width: 0;
  width: 100%;
}

.darker {
  color: var(--bs-gray-400)
}

.game-link {
  text-decoration: underline!important;
}

.padding-top {
  display: block;
  margin-top: 15px;
}

.sidebar-wrapper {
  /* min-height: calc(100vh-60px) !important; */
  width: 100%;
  margin-left: -1rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

.sidebar {
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 50px;
  right: 0;
  margin: 60px 0 0;
}

.friends-list {
  overflow: auto;
}

.friends-btn {
  display: block!important;
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 0 30px 5px 0;
}

.login-btn {
  display: flex;
  justify-content: center;
  margin-top: 10rem;
}